import { Form, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { FC } from 'react'
import Modal from '../../components/base/Modal'


interface IExaminerModal {
    name: string
    onClose: () => void
    onSubmit: () => void
    readOnly?: boolean
}

const EmploymentInvitationModal:FC<IExaminerModal> = ({name, onSubmit, onClose, readOnly=false}) => {

    return (
        <Modal title={'Employment Offer from'} className={'w-[70%]'} onCancel={onClose} 
            onSubmit={() => onSubmit()} submitText={readOnly ? 'Accept Offer' : 'Send'} requiresConfirmation >
            <Form
                name="examinerForm"
                className={'w-2/3 grid grid-cols-2 gap-2'}
                autoComplete="off"
                layout={'horizontal'}
                requiredMark={false}
                title={'Employment Offer'}
                initialValues={{
                    job_title: 'Economic Advisor',
                    Employer: 'National Oils',
                    responsible_person_email:  name,
                    description: `Congratulations  ${name}! We are happy to let you know that you are chosen for the Economic Advisor Role at National Oils. If you have further questions please communicate with us in email at`
                }}    
            >
                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="job_title"
                    rules={[{ required: true, message: 'Institution name Required!' }]}
                >
                    <Input placeholder={'Economic Advisor'} disabled/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-4'}
                    name="Employer"
                    rules={[{ required: true, message: 'Institution address Required!' }]}
                >
                    <Input placeholder={'National Oils'} disabled/>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-2'}
                    name="responsible_person_email"
                    rules={[{ required: true, message: 'Email is required!' }]}
                >
                    <Input placeholder={'Raked Yonas'} type={'email'} disabled />
                </Form.Item>

                
                <Form.Item 
                    className={`col-span-1 md:col-span-4 ${!readOnly ? 'row-span-2' : ''}`}
                    name="description"
                >
                    {!readOnly ?
                    <TextArea disabled={readOnly} placeholder={'Congratulations Rakeb Yonas! We are happy to let you know that you are chosen for the Economic Advisor Role at National Oils. If you have further questions please communicate with us in email at hr@nationaloils.gov.et .'} rows={8} /> :
                    <p className={'bg-fw-green text-md text-white p-2 rounded-md font-medium'}>
                        {`Congratulations ${name}! We are happy to let you know that you are chosen for the Economic Advisor Role at National Oils. If you have further questions please communicate with us in email at hr@nationaloils.gov.et .`}
                    </p>}
                </Form.Item>
                
            </Form> 
            <p className={'mt-2 text-gray-400 text-md'}>If you agree to our 
            <a href="https://www.ilo.org/dyn/natlex/docs/WEBTEXT/31977/64870/E93ETH01.HTM" target={'_blank'} className={'px-1'} rel="noreferrer">terms</a>
             of employment, please click Accept button.</p>

        </Modal>
    )
}

export default EmploymentInvitationModal