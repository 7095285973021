import { Avatar, Button, Form, Input, InputNumber } from 'antd'
import FileUploader from '../../components/base/FileUploader';
import TextArea from 'antd/lib/input/TextArea'
import { IProgram } from '../../types'
import { useState } from 'react';
import {LinkedinOutlined } from '@ant-design/icons'
import { updateProgram, createProgram } from '../../services/UserService';
import useAlert from '../../components/toast/useAlert';

interface IAddProgramForm {
    EditValues? : IProgram,
    id? : Number,
    onClose: () => void
    onFinish: () => void
}

const AddProgramForm = ({EditValues, id, onClose, onFinish}:IAddProgramForm) => {
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [photoFile] = useState<any>(EditValues?.logo ?? "https://dev.fairway.work/media/business/business_logo_placeholder.png"
    );
    const {addAlert} = useAlert();
    const onSubmit = (values: IProgram) => {
        const formData = new FormData();
        
        if (selectedFile) {
            formData.append("logo", selectedFile);
            values.logo = selectedFile;
          }
          // Append other fields from the values object to the FormData
          Object.entries(values).forEach(([key, value]) => {
            if(value !== undefined)
                formData.append(key, value);
          });

        id ? 
        updateProgram(id, formData).then(res=>{
            addAlert({type: 'success', title: 'success', description: 'Program Updated Successully!', autoClose: true})
            onFinish()
        }).catch(err => {
            console.log(err)
            addAlert({type: 'error', title: 'error', description: 'Failed To Submit!', autoClose: true})
        })
        :
        createProgram(formData).then(res=>{
            addAlert({type: 'success', title: 'success', description: 'Program Created Successully!', autoClose: true})
            onFinish()
        }).catch(err => {
            console.log(err)
            addAlert({type: 'error', title: 'error', description: 'Failed To Submit!', autoClose: true})
        })
    }
    console.log("name: ",EditValues?.name);
    
  return (
    <div className={'bg-white shadow rounded-xl p-12 w-auto max-w-2xl lg:max-w-3xl xl:max-w-4xl'}>
        <div className='text-lg md:text-xl text-gray-600 font-semibold pb-4'>{EditValues ? 'Edit a Program' :'Add a Program'}</div>
    <Form
                    name="UniversityAccount"
                    initialValues={EditValues}
                    onFinish={onSubmit}
                    autoComplete="off"
                    className={'w-full grid grid-cols-1 md:grid-cols-2 gap-x-4'}
                >
                    <div className='col-span-2 mb-5'>
                        <div style={{maxWidth: 200}}>
                            <Avatar src={photoFile} size={150} />
                            <FileUploader
                                onFileSelectSuccess={(file) => {setSelectedFile(file)}}
                                onFileSelectError={({ error }) => alert(error)}
                            />
                            {selectedFile && <span className={'text-sm text-gray-500'}>Selected File:{selectedFile?.name ?? 'No File Selected'}</span>}
                        </div>
                    </div>
                    <Form.Item
                        name="name"
                        className={'col-span-2 md:col-span-1 row-span-2'}
                        rules={[{ required: true, message: 'Program name is required!' }]}
                    >
                        <Input disabled={EditValues?.name ? true : false} placeholder={'Program Name'} />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        className={'col-span-2 md:col-span-1 row-span-2'}
                        rules={[{ required: true, message: 'Program Code is required!' }]}
                    >
                        <Input placeholder={'Program Code'} />
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-2 md:col-span-1 row-span-2'}
                        name="weblink"
                    >
                        <Input addonBefore={<LinkedinOutlined />}/>
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-2 md:col-span-1 row-span-2'}
                        name="duration"
                        rules={[{ required: true, message: 'Program Duration is required!' }]}
                    >
                        <Input type='number' addonBefore='Duration' placeholder={'in months'} className={'w-full'}/>
                    </Form.Item>

                    <Form.Item 
                        className={'col-span-1 md:col-span-2 row-span-2'}
                        name="description"
                    >
                        <TextArea placeholder={'Program description'}  rows={4} />
                    </Form.Item>

                    <Button  className='col-span-2 md:col-span-1' onClick={() => onClose()} block type="default">
                        Cancel
                    </Button>
                    <Button block className={'mt-5 md:mt-0 col-span-2 md:col-span-1'} type="primary" htmlType="submit">
                        {EditValues ? 'Update': 'Submit'}
                    </Button>
                </Form>
    </div>
  )
}

export default AddProgramForm