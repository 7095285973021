import {EnvironmentOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Button} from "antd";
import moment from "moment";
import { ICredential} from "../../types";
import Avatar from "antd/lib/avatar/avatar";
import { useState } from "react";
import useDevice from "../../hooks/useDevice";
import { useAuth } from '../../context/AuthContext';
import { IssueCredential, RejectCredential } from "../../services/UserService";
import useAlert from "../../components/toast/useAlert";
import Modal from "../../components/base/Modal";

interface ICredentialListItem {
    credential: ICredential;
    onIssue: ()=> void;
    onRejct: () => void;
    onCheckStatus : () => void;
    loading : boolean
}


const CredentialListItem = ({credential, onIssue, onCheckStatus, loading}:ICredentialListItem)=> {
    const { isMobile } = useDevice();
    const {currentUser} = useAuth();
    const [applyLoading, setApplyLoading]= useState<boolean>(false)
    const [RejectLoading, setRejectLoading]= useState<boolean>(false)
    // const [credentialIssued, setCredentialIssued]= useState<boolean>(false)
    const [showConnectionInstruction, setShowConnectionInstruction] = useState<boolean>(credential.status === 0)
    const [showDetail, setShowDetail] = useState<boolean>(false)
    const {addAlert} = useAlert();

    const onViewDetail = () => {
        setShowDetail(prev => !prev)
    }   

    // REQUESTED = 0
    // CONNECTED = 1
    // PENDING = 2
    // ISSUED = 3

    const handleIssue = ()=> {
        setApplyLoading(true);
        IssueCredential({credential_request : credential.id}).then(res => {
            setApplyLoading(false)
            addAlert({type: 'success', title: 'success', description: 'Credential Issued Successully!', autoClose: true})
            onIssue()
        }).catch(err=> {
            console.error(err)
            addAlert({type: 'error', title: 'error', description: 'Failed To Issue Credential!', autoClose: true})
            setApplyLoading(false)
        })
    }
    const onReject = () => {
        setRejectLoading(true)
        RejectCredential({credential_request : credential.id}).then(res => {
            setRejectLoading(false)
            addAlert({type: 'success', title: 'success', description: 'Credential Rejected Successully!', autoClose: true})
        }).catch(err=> {
            console.error(err)
            addAlert({type: 'error', title: 'error', description: 'Failed To Reject Credential!', autoClose: true})
            setRejectLoading(false)
        })
        
    }
    const onCloseInstruction = () => {
        setShowConnectionInstruction(false)
    }
    const ProccedInstruction = () => {
        setShowConnectionInstruction(false)
        setTimeout(() => onCheckStatus(), 3000)
    }
    
    return(
        <>
        {currentUser?.is_university_staff && credential.status !== 0 && <div className={`${credential.is_issued ? 'bg-gray-200 opacity-90' : 'bg-white'} cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto xl:max-w-[85rem] px-6 py-4`}>
            <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-1 md:grid-rows-2 lg:grid-rows-1 gap-3 md:gap-4 items-center'}>
                    <div className={'col-span-1 md:col-span-1 flex items-center overflow-hidden'}>
                        <Avatar
                            size={isMobile ? 60 : 80}
                            className={'flex shrink-0'}
                            src={credential?.user?.photo ?? '/company-s.png'}
                            shape={'circle'}
                        />
                        <div className={'flex flex-col ml-4 xl:w-full'}>
                            <p className={'font-semibold text-lg text-gray-900 pb-1 capitalize w-full flex-wrap'}>{credential?.user?.first_name + ' ' + credential?.user?.last_name}</p>
                            <p className={'font-light text-md text-gray-700 pb-1 capitalize'}>{credential?.program.name}</p>
                            <p className={'font-thin text-sm text-gray-500 pb-1'}>Requested {credential?.requested_at ? moment(credential?.requested_at).fromNow(): ''}</p>
                        </div>

                    </div>

                    <div className={'col-span-1'}>
                        <div className={'flex flex-row items-start w-full'}>
                            <div className={'flex flex-col mx-2 h-auto md:max-h-26 pt-2 overflow-hidden'}>
                                <div className={'font-light text-sm text-gray-400'}>Student ID <span className="font-bold">{credential?.student_id ?? "xxxxx"}</span></div>
                                <div className={'font-light text-sm text-gray-400'}>Year of Enrollment <span className="font-bold">{credential?.year_of_enrollment ?? "xxxxx" }</span></div>
                                {/* <div className={'font-light text-sm text-gray-400'}>Contact Phone  <div className="font-bold">{credential?.phone}</div></div> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className={'col-span-1 md:col-span-2'}>
                        <div className={'w-full h-full flex flex-row items-center flex-wrap md:flex-nowrap'}>
                            <div className={'flex flex-row gap-3 items-start w-full xl:w-max mb-4 flex-wrap md:flex-nowrap md:gap-x-2 justify-start md:justify-between xl:justify-around md:mb-0'}>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_gray rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <EnvironmentOutlined className={'font-semibold text-gray-600 transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-xs inline-block truncate'}>{`${credential?.university.city}`}</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_yellow rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        {/* <BarChartOutlined className={'font-semibold text-fw_job_shiro transform scale-125 md:scale-175'} /> */}
                                        <p className="font-semibold text-gray-600">{credential?.year_of_graduation}</p>
                                        <p className={'text-gray-600 text-xs inline-block  whitespace-pre-wrap w-full text-center'}>Graduate</p>
                                    </div>
                                </div>

                                <div className={'flex flex-row'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_green rounded-xl flex flex-col justify-between items-center'}>
                                        <Avatar
                                            size={isMobile ? 60 : 80}
                                            className={'flex shrink-0'}
                                            src={credential.program.logo ?? '/company-s.png'}
                                            shape={'square'}
                                        />
                                        {/* <img src={credential.program.logo} alt={credential.program.name}/> */}
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-col items-start w-full xl:w-full md:ml-4'}>
                                <div className={'flex flex-row w-full items-end justify-start lg:justify-between flex-wrap gap-2 lg:flex-col lg:h-20'}>
                                    <Button onClick={() => {if(credential.status === 1) handleIssue()}} disabled={credential?.status === 2 || credential?.status === 3 || credential?.status === 4} loading={applyLoading} className={`rounded-full max-w-[150px] ${credential?.status === 3 && 'bg-green-400'} ${credential?.status === 2 && 'px-2'} lg:w-full font-semibold`} type={'primary'}>{credential.status === 1 ? 'Issue' :  credential.status === 2 ? 'Waiting Acceptance' : credential.status === 4 ? 'Rejected' :  "Issued"}</Button>
                                    {<Button onClick={credential.status === 1 ? onReject : onViewDetail} loading={RejectLoading} className={`rounded-full max-w-[150px] lg:w-full ${credential.status === 1 ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'} border-0 font-semibold`} type={'primary'}>{credential.status === 1 ? 'Reject': <span><QrcodeOutlined />view</span>}</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>}
        {!currentUser?.is_university_staff && <div className={`${credential.is_issued ? 'bg-gray-200 opacity-90' : 'bg-white'} cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto xl:max-w-[85rem] px-6 py-4`}>
            <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-1 md:grid-rows-2 lg:grid-rows-1 gap-3 md:gap-4 items-center'}>
                    <div className={'col-span-1 md:col-span-1 flex items-center overflow-hidden'}>
                        <Avatar
                            size={isMobile ? 60 : 80}
                            className={'flex shrink-0'}
                            src={credential?.user?.photo ?? '/company-s.png'}
                            shape={'circle'}
                        />
                        <div className={'flex flex-col ml-4 xl:w-full'}>
                            <p className={'font-semibold text-lg text-gray-900 pb-1 capitalize w-full flex-wrap'}>{credential?.user?.first_name + ' ' + credential?.user?.last_name}</p>
                            <p className={'font-light text-md text-gray-700 pb-1 capitalize'}>{credential?.program.name}</p>
                            <p className={'font-thin text-sm text-gray-500 pb-1'}>Requested {credential?.requested_at ? moment(credential?.requested_at).fromNow(): ''}</p>
                        </div>

                    </div>

                    <div className={'col-span-1'}>
                        <div className={'flex flex-row items-start w-full'}>
                            <div className={'flex flex-col mx-2 h-auto md:max-h-26 pt-2 overflow-hidden'}>
                                <div className={'font-light text-sm text-gray-400'}>Student ID <span className="font-bold">{credential?.student_id ?? "xxxxx"}</span></div>
                                <div className={'font-light text-sm text-gray-400'}>Year of Enrollment <span className="font-bold">{credential?.year_of_enrollment ?? "xxxxx" }</span></div>
                                {/* <div className={'font-light text-sm text-gray-400'}>Contact Phone  <div className="font-bold">{credential?.phone}</div></div> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className={'col-span-1 md:col-span-2'}>
                        <div className={'w-full h-full flex flex-row items-center flex-wrap md:flex-nowrap'}>
                            <div className={'flex flex-row gap-3 items-start w-full xl:w-max mb-4 flex-wrap md:flex-nowrap md:gap-x-2 justify-start md:justify-between xl:justify-around md:mb-0'}>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_gray rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <EnvironmentOutlined className={'font-semibold text-gray-600 transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-xs inline-block truncate'}>{`${credential?.university.city}`}</p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_yellow rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        {/* <BarChartOutlined className={'font-semibold text-fw_job_shiro transform scale-125 md:scale-175'} /> */}
                                        <p className="font-semibold text-gray-600">{credential?.year_of_graduation}</p>
                                        <p className={'text-gray-600 text-xs inline-block  whitespace-pre-wrap w-full text-center'}>Graduate</p>
                                    </div>
                                </div>

                                <div className={'flex flex-row'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_green rounded-xl flex flex-col justify-between items-center'}>
                                        <Avatar
                                            size={isMobile ? 60 : 80}
                                            className={'flex shrink-0'}
                                            src={credential.program.logo ?? '/company-s.png'}
                                            shape={'square'}
                                        />
                                        {/* <img src={credential.program.logo} alt={credential.program.name}/> */}
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-col items-start w-full xl:w-full md:ml-4'}>
                                <div className={'flex flex-row w-full items-end justify-start lg:justify-between flex-wrap gap-2 lg:flex-col lg:h-20'}>
                                   <Button onClick={onViewDetail} type='primary' disabled={credential.status === 1 || credential.status === 4} className={`rounded-full max-w-[150px] ${credential.status === 3 && 'bg-green-400'} lg:w-full font-semibold`}>{credential.status === 0 ? 'Complete Request' : credential.status === 1 ? 'Pending' : credential.status === 2 ? 'Accept' : credential.status === 4 ? 'Rejected' : 'Accepted'}</Button>
                                    {(credential.status === 0 || credential.status === 2) && <Button disabled className={'rounded-full max-w-[150px] lg:w-full text-green-500 bg-green-100 border-0 font-semibold'}>Action needed</Button>}
                                    {(credential.status ===1 || credential.status === 4)  && <Button onClick={onViewDetail} className={'rounded-full max-w-[150px] lg:w-full text-green-500 bg-green-100 border-0 font-semibold'}>View</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>}
        {showDetail && <div className="cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto xl:max-w-[85rem] px-6 min-h-[200px] -my-5 border-dotted border-gray-200 border-t-1 border-b-0 border-x-0 bg-white">
            <div className="flex gap-2 md:gap-6">
            <div>
                <div className="flex gap-2 flex-col md:flex-row items-center py-6">
                    <img className="h-[20px] lg:h-[50px]" src="/proofspace.jpg" alt="proofspace" />
                    <img className="w-[150px] lg:w-[250px]" src="/fairway_logo_black_text.png" alt="fairway" />
                </div>
                <div className="flex gap-12 pb-6">
                    <div className={'flex items-center overflow-hidden'}>
                        <Avatar
                            size={isMobile ? 60 : 80}
                            className={'flex shrink-0'}
                            src={credential?.university?.logo ?? '/company-s.png'}
                            shape={'circle'}
                        />
                        <div className={'flex flex-col ml-4 xl:w-full'}>
                            <p className={'font-semibold text-sm md:text-lg text-gray-900 pb-1 capitalize w-full flex-wrap'}>{credential?.university?.name}</p>
                            {/* <p className={'font-light text-md text-gray-700 pb-1 capitalize'}>Issued to</p> */}
                            <p className={'font-thin text-sm text-gray-500 pb-1'}>{credential?.requested_at ? moment(credential?.requested_at).fromNow(): ''}</p>
                        </div>
                    </div>
                    {/* <div className={'flex flex-col mx-2 h-auto md:max-h-26 pt-2 overflow-hidden'}>
                                <div className={'font-light text-sm text-gray-400'}>Proofspace Service ID <span className="font-bold">{credential?.university.credential_id}</span></div>
                                <div className={'font-light text-sm text-gray-400'}>Credential ID <span className="font-bold">{credential?.university.credential_id}</span></div>
                    </div> */}
                </div>
            </div>
            
            <div className="ml-auto flex flex-col md:flex-row gap-2 justify-center items-center my-4 md:my-0">
                    {credential.status === 0 &&  <img className="h-[150px] md:h-[200px]" src={credential.university.qr_code} alt="qr to scan" />}
                    <div className="flex flex-col gap-2">
                    {!currentUser?.is_university_staff && credential.status === 1 && <p className="max-w-[200px] min-w-[120px] text-green-300">Wait for Your University to issue the Credential!</p>}
                    {!currentUser?.is_university_staff && credential.status === 0 && <p className="max-w-[200px] min-w-[120px] text-green-300">Connect to Your University!</p>}
                    {!currentUser?.is_university_staff && credential.status === 0 && <p className="max-w-[200px] min-w-[120px]">Please Scan this QR Code to go to the proofspace app and Connect to your University!</p>}
                    {!currentUser?.is_university_staff && credential.status === 2 && <p className="max-w-[200px] min-w-[120px] text-green-300">Request has been sent to you!</p>}
                    {!currentUser?.is_university_staff && credential.status === 2 && <p className="max-w-[200px] min-w-[120px]">Please go to the proofspace app and Accept this request!</p>}
                    {currentUser?.is_university_staff && credential.status === 2 && <p className="max-w-[200px] min-w-[120px] text-green-300">The request has been sent to the student, The status will change when the student accepts the request.</p>}
                    {credential.status !==  3 && credential.status !==  4 && <Button size="small" loading={loading} className={'max-w-[150px] bg-green-300 text-green-600 lg:w-full font-semibold'} onClick={onCheckStatus}>Check Status</Button>}
                    {credential.status === 3 && <p className="max-w-[200px] min-w-[120px] text-green-300 font-semibold">This Credential request is Approved! for more details go to Proofspace App!</p>}
                    {credential.status === 4 && <p className="max-w-[200px] min-w-[120px] text-red-300 font-semibold">This Credential request has been Rejected! Please contact your university for futher details.</p>}
                    </div>
                    
                    {currentUser?.is_university_staff && (credential.status === 4 || credential.status === 3) && <img className="h-[200px]" src="/lock.png"alt="locked" />}
                </div>

        </div>

        </div>}
        {!currentUser?.is_university_staff && showConnectionInstruction && <Modal className="md:w-[600px]" title='Connect to your University' subTitle="Go to Proofspace app and Connect to your University" onCancel={onCloseInstruction} onSubmit={ProccedInstruction} submitText="Procced">
        <ul className="pt-6">
            <li>Scan the QR code to download Proofspace app.</li>
            <li>After initializing Proofspace, use the in-app scanner to scan the code again.</li>
            <li>Fill out your information and make sure to enter the email you used on Fairway.</li>
            <li>Click Proceed to establish a connection with your university. You will receive your credential once the university approves it.</li>
        </ul>
        <div className="flex gap-6 ml-6">
            <img className="h-[200px]" src={credential.university.qr_code} alt="qr to scan" />
            <div>
                <div><span className="font-bold">University Name:</span> {credential.university.name}</div>
                <div><span className="font-bold">Program:</span> {credential.program.name}</div>
                <div>Click Procced After you have Completed all the above steps</div>
            </div>
        </div> 
        </Modal>}
        </>
    )
}

export default CredentialListItem;
