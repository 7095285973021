
import { useMemo } from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import ResetPassword from "../components/onboarding/ResetPassword";
import SignUp from "../components/onboarding/SignUp";
import SignUpBusiness from "../components/onboarding/SignUpBusiness";
import { useAuth } from "../context/AuthContext";
import Account from "../pages/Account";
import CVDetail from "../pages/cv/CVDetail";
import CVEdit1 from "../pages/cv/CVEdit1";
import CVEdit2 from "../pages/cv/CVEdit2";
import CVEdit3 from "../pages/cv/CVEdit3";
import CVEdit4 from "../pages/cv/CVEdit4";
import CVEdit5 from "../pages/cv/CVEdit5";
import Dashboard from "../pages/Dashboard";
import EmploymentPage from "../pages/employment/EmploymentPage";
import { ErrorPage } from "../pages/error/ErrorPage";
import CreateJob from "../pages/jobs/CreateJob";
import JobDetail from "../pages/jobs/JobDetail";
import MyJobsTabPage from "../pages/jobs/MyJobsTabPage";
import Landing from "../pages/onboarding/Landing";
import ComponentsDemo from "../pages/selection/components";
import SelectionRoot from "../pages/selection/SelectionRoot";
import ResetPasswordPage from "../components/onboarding/ResetPasswordPage";
import LoginPage from "../components/onboarding/new/loginPage";
import SignUpPage from "../components/onboarding/new/signupPage";
import ResetPassPage from "../components/onboarding/new/resetPasswordPage";
import MyPrograms from "../pages/programs/MyPrograms";
import EditProgram from "../pages/programs/EditProgram";
import MyCredential from "../pages/credential/MyCredential";

const Router = ()=> {
  const {isLoggedIn, currentUser} = useAuth();
  
  const isAllowed = useMemo(() => {
    const url = window?.location?.pathname
    const isJobDetailRoute = url?.includes("dashboard/jobs") ?? false
    if (isLoggedIn() || isJobDetailRoute) {
      return true;
    } 
    return false;
  }, [isLoggedIn]);

  return (
        <BrowserRouter>
        {/* !isLoggedIn() ? <Landing /> : <Navigate to="/dashboard" /> */}
            <Routes>
                <Route path="/" >
                  <Route index element={isAllowed ? <Navigate to="/dashboard" state={{replace: true}} /> : <Landing />} />
                  <Route path="signup/jobseeker" element={<SignUp />} />
                  <Route path="signup/business/:token" element={<SignUpBusiness />} />
                  <Route path="waitlist/jobseeker" element={<SignUp />} />
                </Route>
                <Route path="reset-password" element={<ResetPasswordPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignUpPage />} />
                <Route path="reset-password" element={<ResetPassPage />} />
                <Route path="dashboard" element={isAllowed ? <Dashboard /> : <Navigate to="/" state={{replace: true}} />}>
                        <Route index element={currentUser?.is_university_staff ?  <Navigate to="/dashboard/credentials" state={{replace: true}} /> :<MyJobsTabPage />} />
                        <Route path="jobs/create" element={<CreateJob />} />
                        <Route path="jobs/:jobid/edit" element={<CreateJob isEditing={true} />} />
                        <Route path="jobs/:jobid" element={<JobDetail />} />
                        <Route path="cv/:cvId" element={<CVDetail />} />
                        <Route path="cv" element={<CVDetail />} />
                        <Route path="cv/edit/1" element={<CVEdit1 />} />
                        <Route path="cv/edit/2/:cvId" element={<CVEdit2 />} />
                        <Route path="cv/edit/3/:cvId" element={<CVEdit3 />} />
                        <Route path="cv/edit/4/:cvId" element={<CVEdit4 />} />
                        <Route path="cv/edit/5/:cvId" element={<CVEdit5 />} />
                        <Route path="account" element={<Account />} />
                        <Route path="selection/demo" element={<ComponentsDemo />} />
                        <Route path="selection/:id" element={currentUser?.is_staff === false ? <Navigate to="/dashboard" /> : <SelectionRoot /> } />
                        <Route path="employments" element={currentUser?.is_staff === false ? <Navigate to="/dashboard" /> : <EmploymentPage />} />
                        <Route path="credentials" element={!currentUser?.is_staff === false ? <Navigate to="/dashboard" /> : <MyCredential />} />
                        <Route path="programs" element={currentUser?.is_university_staff === false ? <Navigate to="/dashboard" /> : <MyPrograms />} />
                        <Route path="programs/:id" element={currentUser?.is_university_staff === false ? <Navigate to="/dashboard" /> : <EditProgram />} />
                </Route>
                <Route path="/404" element={<ErrorPage is404={true} />} />
                <Route
                  path="*"
                  element={<ErrorPage is404={true} />}
                />
            </Routes>
        </BrowserRouter>
  );
}

export default Router;