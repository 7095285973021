import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import { Form, Input, Checkbox, Button, Alert } from "antd";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "../../Utility";
import useAlert from "../toast/useAlert";

interface ILogin {
    redirectTo?: string 
    onSignup? :() => void
    onForgetPassword : () => void
}

const Login:FC<ILogin> = ({redirectTo = '', onSignup, onForgetPassword}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError ] = useState<string>('');
    const [form] = Form.useForm();
    const authContext = useAuth();
    const {addAlert} = useAlert();

    const navigate = useNavigate();
    const query = useQuery()

    const redirectUrl = useMemo(() => {
        const url = query.get('redirectTo')
        return redirectTo ? redirectTo : url ? url : '/dashboard';
    }, [query, redirectTo]);

    const onLogin =(values: any)=> {
        if(values && values.email && values.password) {
            setLoading(true);
            authContext.logIn({email: values.email, password:values.password, onSuccess, onError})
        }
    }

    const onSuccess = ()=> {
        setLoading(false);
        setTimeout(()=> {
            navigate(redirectUrl)
        }, 300)
    }
    const onError = (errData:any)=> {
        addAlert({type: 'error', title: 'error', description: JSON.stringify(errData), autoClose:false})
        setLoading(false)  
    }

    const onForgetPasswordClick = () => {
        // navigate('/reset-password')
        onForgetPassword()
    }

    const handleSignup = () => {
        if(!redirectTo) {
            navigate(`signup/jobseeker${query.get('redirectTo') ? `?redirectTo=${query.get('redirectTo')}` : ''}`)
            return
        }
        // Your logic to render signup Modal
        onSignup?.()
    }

    return (
        <div className={'flex flex-col items-center'}>
            <Form
                form={form}
                name="login"
                initialValues={{ remember: true}}
                onFinish={onLogin}
                className={'w-full'}
            >
                <Form.Item 
                    className={'mb-4 mt-4 md:mt-12'}
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }, {type: 'email', message: 'Please enter a valid email'}]}
                >
                    <Input placeholder={'email'} prefix={<MailOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>
        
                <Form.Item
                    className={'mb-0'}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder={'password'}  prefix={<LockOutlined className="site-form-item-icon mr-2" />} />
                </Form.Item>
        
                <div className={'w-full flex flex-row items-center my-2 justify-between'}>
                    <Form.Item name="remember" valuePropName="checked" className={'mb-0'}>
                        <Checkbox className={'text-xs text-gray-400 mt-1'}>Remember me</Checkbox>
                    </Form.Item>
                    <p onClick={onForgetPasswordClick} className={'text-xs text-fw-orange font-medium pt-1 cursor-pointer'}>Forgot password?</p>
                </div>
                <Button loading={loading} disabled={loading} block type="primary" htmlType="submit">
                    Log In
                </Button>

                <div className={'w-full flex flex-row items-center my-2 justify-between'}>
                    <p className={'text-xs text-gray-400 font-medium pt-1 cursor-pointer'}>Not Registered Yet?</p>
                    <p onClick={handleSignup} className={'text-xs text-fw-orange font-medium pt-1 cursor-pointer'}>Signup</p>
                </div>
                {!redirectTo && <Button block type={'default'} className={'mt-2'} htmlType="submit">
                    <a target="_blank" href="https://fairway.global" rel="noreferrer">
                        Register as a Business
                    </a>
                </Button>}
            </Form>
            {error ? (
                <Alert message={error} type="error" closable afterClose={() => setError('')} />
            ) : null}
        </div>
    );
}
export default Login;