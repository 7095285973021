import { Form, Input, Steps, Avatar, Button, Select, Divider, Alert, DatePicker, Space, Progress } from "antd";
// import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import { MinusCircleOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { BusinessSkills, EthiopianCities, ICVProfile, ILanguage } from "../../types";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { getCVProfile, updateCVProfile } from "../../services/CVService";
import { arrayIsEmpty, isEmpty } from "../../Utils";
import { AllCountries, allLanguages, convertMaptoArr, getProfileImgUrl, noteToJobSeeker } from "../../Utility";
import useAlert from "../../components/toast/useAlert";
import PageSpinner from "../../components/base/PageSpinner";
import SectionSeparator from "../../components/base/SectionSeparator";
import FWSubHeader from "../../components/base/FWSubHeader";
import Page from "../../components/base/Page";
import moment from "moment";
import Bullet from "../../components/base/Bullet";
import { languageProficiencyLevels } from "./CVDetail";

const CVEdit1 = ()=> {

    const {currentUser, cvId} = useAuth();
    const navigate = useNavigate();
    const [profileForm] = Form.useForm();
    const {addAlert} = useAlert();

    const [loading, setLoading] = useState<boolean>(false);
    const [cvProfile, setCVProfile ] = useState<ICVProfile>({} as ICVProfile);

    const [cities, setCities] = useState<string[]>(EthiopianCities);
    const [newCity, setNewCity] = useState<string>('');
    
    const [skills, setSkills] = useState<string[]>(BusinessSkills);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    
    const { Step } = Steps;
    const { Option } = Select;

    useEffect(()=> {       
        if(cvId) {
            setLoading(true);
            getCVProfile(cvId)
            .then(res => {
                const profile = res?.data[0];
                // setCVProfile({...profile, languages: []})
                setCVProfile({...profile})
                setIsCreating(arrayIsEmpty(res?.data) ? true : false)
                if(profile?.skills?.length >0) {
                    setSkills(profile?.skills)
                }
                // if(Object.keys(profile?.languages?.length).length >0) {
                //     setLanguages(profile?.languages)
                // }
                if(!isEmpty(profile?.languages)) {
                   const langArrOfObjs = convertMaptoArr(profile?.languages, true) as Record<string, any>[]
                   console.log('arrOfObjs', langArrOfObjs);
                }
            })
            .finally(()=> setLoading(false))
        }
    }, [cvId, reload])

    // const onNewLangChange = (event:any) => {
    //     setNewLanguage(event.target.value)
    // };
    
    // const addLang= () => {
    //     if(newLanguage !== '') {
    //         setLanguages(prev => [...prev, newLanguage])
    //         const values = profileForm.getFieldsValue(true);
    //         profileForm.setFieldsValue({...values, languages: [...values.languages, newLanguage]})
    //         setNewLanguage('');
    //     }
    // };

    const onNewCityChange = (event:any) => {
        setNewCity(event.target.value)
    };
    
    const addCity = () => {
        if(newCity !== '') {
            setCities(prev => [...prev, newCity])
            const values = profileForm.getFieldsValue(true);
            profileForm.setFieldsValue({...values, city: newCity})
            setNewCity('')
        }
    };

    const onSubmitCVProfile = (values:ICVProfile, nextPage?:boolean)=> {
        if(cvId && values && currentUser?.profile) {
            const {first_name, last_name, phone_number} = currentUser?.profile;
            console.log('values', values)
            const newCVProfile = Object.assign({}, { 
                first_name,
                last_name,
                email: currentUser.email,
                phone_number,
                city: values.city,
                country: values.country,
                title: values.title,
                bio: values.bio,
                skills: values.skills,
                languages: values.languages,
                socials: values.socials ? [values.socials.toString()] : []
            })
            setLoading(true)
            updateCVProfile(cvId, newCVProfile)
            .then(res => {
                setReload(prev => !prev)
                if(!nextPage) {
                    
                } else {
                    navigate(`/dashboard/cv/edit/2/${cvId}`)
                }
            })
            .catch(err => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(err?.response?.data), autoClose: false})
            })
            .finally(()=> setLoading(false))
        }

    } 

    const getInitialCVProfileData = ()=> {
        return cvProfile;
    }

    const onChangeStep = (newStep: number) => {
        if(isCreating) {
            return
        }
        if(newStep === 1) {
            navigate(`/dashboard/cv/edit/2/${cvId}`)
        }
        if(newStep === 2) {
            navigate(`/dashboard/cv/edit/3/${cvId}`)
        }
        if(newStep === 3) {
            navigate(`/dashboard/cv/edit/4/${cvId}`)
        }
        if(newStep === 4) {
            navigate(`/dashboard/cv/edit/5/${cvId}`)
        }
    }

    return(
        <Page>
            <FWSubHeader title={'Edit CV (1/5): Personal Info'} className="flex-col" />
                {/* <span onClick={() => navigate('/dashboard/cv', {state: {isFromCv:true}})} className={'flex ml-4 items-center cursor-pointer'}>
                        <ArrowLeftOutlined className={'mr-2 text-fw-orange font-medium scale-150'} />
                        <p className={'text-xs text-gray-600'}>Back to CV</p>
                </span> */}
            <Steps responsive className={'px-4 mt-4 sm:mb-4  w-full max-w-3xl xl:max-w-4xl'} onChange={onChangeStep} current={0} size={'small'}>
                <Step title="Personal Info" />
                <Step title="Experience"  />
                <Step title="Education" />
                <Step title="Achievements" />
                <Step title="Certifications" />
            </Steps>
            <div className={'bg-white p-4 shadow rounded-md md:rounded-xl w-auto max-w-3xl xl:max-w-4xl'}>
                <div className="w-full h-2 flex justify-end -mb-2"><CloseOutlined onClick={() => navigate(-1)} className="hover:text-fw-orange cursor-pointer"/></div>
              <div className={'flex flex-col w-full mt-6'}>
            {loading ? <PageSpinner /> :
            <>
            <SectionSeparator colorSecondary={true} small={true} title='Your Account Profile' toolTip="Please go to account page to edit your name" />
            <Form
                name="addPersonalDetails"
                autoComplete="off"
                className={'grid grid-cols-1 md:grid-cols-3 gap-2'}
            >   
                <Avatar className={'row-span-3 md:self-center md:justify-self-center'} src={getProfileImgUrl(currentUser)} size={120} />
                <Form.Item>
                    <Input value={currentUser?.profile?.first_name} disabled={true} placeholder={'First name'} />
                </Form.Item>

                <Form.Item>
                    <Input value={currentUser?.profile?.last_name} disabled={true} placeholder={'Last name'} />
                </Form.Item>

                <Form.Item>
                    <Input value={currentUser?.profile?.phone_number} disabled={true} className={'w-full'} maxLength={10} placeholder={'09********'} />
                </Form.Item>

                <Form.Item>
                    <Input value={currentUser?.email} disabled={true} className={'w-full'} maxLength={10} placeholder={'john@test.com'} />
                </Form.Item>

                <Form.Item>   
                    <DatePicker value={currentUser?.profile?.date_of_birth ? moment(currentUser?.profile?.date_of_birth) : null} className={'w-full'} disabled placeholder={'Date of Birth'} />
                </Form.Item>

                <Form.Item>
                    <Input value={currentUser?.profile?.gender === '' ? 'N/A' : currentUser?.profile?.gender === 'M' ? 'Male' : 'Female'} disabled={true} className={'w-full'} maxLength={10} placeholder={'Male'} />
                </Form.Item>
                <Button className={'md:col-start-2'} onClick={() => navigate('/dashboard/account', {state: {isFromCv:true}})} block type="dashed">Edit This Info</Button>
            </Form>

            <SectionSeparator colorSecondary={true} small={true}  title='Your CV Profile' />

            <Form
                name="addCVProfile"
                onFinish={(values:any) =>onSubmitCVProfile(values, true)}
                className={'flex flex-col md:grid grid-cols-1 md:grid-cols-3 gap-x-5'}
                initialValues={getInitialCVProfileData()}
                form={profileForm}
            >   
                <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Your Profession is required!' }]}
                >
                    <Input placeholder={'Your Profession'} />
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="city"
                    rules={[{ required: true, message: 'Your city is required!' }]}
                >
                    <Select
                        style={{ width: '100%', height: '100%' }}
                        placeholder="city"
                        listItemHeight={10} listHeight={250} 
                        maxLength={5}
                        showSearch
                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={newCity} onChange={onNewCityChange} />
                                <span
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={addCity}
                                >
                                <PlusOutlined /> Add a city 
                            </span>
                            </div>
                        </div>
                        )}
                        >
                        {cities.map((cityName, i) => {
                            return  <Option key={i} value={cityName.toLowerCase()}>{cityName}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1'}
                    name="country"
                    rules={[{ required: true, message: 'Your country is required!' }]}
                >
                    <Select
                        style={{ width: '100%', height: '100%' }}
                        placeholder="Country"
                        listItemHeight={10} listHeight={250} 
                        showSearch  
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          }      
                        >
                        {AllCountries.map((country, i) => {
                            return  <Option key={i} value={country.code}>{country.name}</Option>
                        })} 
                    </Select>
                </Form.Item>

                <Form.Item 
                    className={'col-span-1 md:col-span-3 row-span-2'}
                    name="bio"
                    rules={[{ required: true, message: 'Please write a brief bio!' }
                        ]}
                >
                    <TextArea showCount maxLength={250} placeholder={'Briefly describe yourself here...'}  rows={3} />
                </Form.Item>
                <Form.Item 
                        className={'col-span-1 md:col-span-3 row-span-2'}
                        name="skills"
                        rules={[{ required: true, message: 'Please add as skill here'},
                                { type: 'array', min:3, message: 'Please add at least 3 skills'},
                                { type: 'array', max:10, message: "Skills can't be more than 10"}
                             ]}
                    >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Add Skills here"
                    >
                        {skills.map((item,i) => (
                            <Option key={i} value={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="socials"
                    className={'col-span-2 md:col-span-3'}
                >
                    <Input placeholder={'Your Linkedin profile link'} />
                </Form.Item>



                <Form.Item 
                className={'col-span-3'}>
                    <SectionSeparator colorSecondary={true} small={true}  title='Languages' />
                    <div className={'flex flex-wrap ml-4'}>
                        {/* {cvProfile?.languages.map((lang: ILanguage,i: number) => {
                            return <Bullet text={lang?.name ?? ''} key={i} className="w-full text-gray-900">
                                <div className="w-full flex justify-between">
                                    <div className="text-md min-w-[55px]">{lang.name}</div>
                                    {<Progress strokeWidth={20} showInfo={false} percent={Number(lang.level)*(200/5)} steps={5} />} 
                                    <div className="text-gray-500 min-w-[100px] text-right">{languageProficiencyLevels(Number(lang.level))}</div>
                                </div>
                            </Bullet>
                        })} */}
                    </div>
                    </Form.Item>
                
                <Form.List name="languages">
                    {(fields, { add, remove }) => (
                        <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline" className={'col-span-2 md:col-span-3 row-span-2'}>
                            <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                rules={[{ required: true, message: 'Missing Language' }]}
                            >
                                <Select
                                    style={{ width: '250px'}}
                                    className={'!w-[100%] md:!w-[250px]'}
                                    placeholder="Search language"
                                    optionFilterProp="children" 
                                    options={allLanguages}
                                />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'level']}
                                rules={[{ required: true, message: 'Missing Proficiency' }]}
                            >
                                <Select
                                    // defaultValue=""
                                    placeholder="Proficiency"
                                    style={{ width: '100%' }}
                                    // onChange={handleChange}
                                    options={[
                                        { value: '', label: 'select level', disabled: true },
                                        { value: '0', label: 'Not Applicable' },
                                        { value: '1', label: 'Beginner' },
                                        { value: '2', label: 'Intermediate' },
                                        { value: '3', label: 'Advanced' },
                                        { value: '4', label: 'Fluent' },
                                        { value: '5', label: 'Native/Bilingual' }
                                    ]}
                                />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Language
                            </Button>
                        </Form.Item>
                        </>
                    )}
                </Form.List>
                <Alert className={'col-span-1 md:col-span-3 mt-4 mb-1 text-xs'} message={noteToJobSeeker} type={'warning'} showIcon />
                <div className={'flex flex-col md:grid gap-2 grid-flow-col col-start-3 justify-self-end'}>
                    <Button onClick={() => navigate('/dashboard/cv')} block type="default">Cancel</Button>
                    <Button loading={loading} disabled={loading}  block type="primary" htmlType="submit">{'Save and Proceed'}</Button>
                </div>
            </Form>
            </>}
        </div>
    </div>    
</Page>)
}

export default CVEdit1;