import Marquee from "react-fast-marquee";


const LogoSpin = () => {
  return (
    <div id='companies' style={{scrollBehavior: 'smooth'}} className='my-12 py-12'>
            <div className='text-3xl text-center font-semibold pb-8 text-gray-800'>Companies</div>
    <Marquee pauseOnHover={true} >
      <div className="flex gap-x-4 gap-y-2 lg:gap-2 xl:gap-8 sm:flex-row flex-wrap flex-row sm:justify-center lg:justify-between items-center mx-[5vw] py-5">
            {/* <a target="_blank" href="https://www.businessfinland.fi/en/for-finnish-customers/home" rel="noreferrer"><img className="filter grayscale hover:filter-none object-cover max-w-[120px] h-[auto]" src="/BusinessFinland-logo.png" alt="business finland"/></a> 
            <a target="_blank" href="https://projectcatalyst.org/" rel="noreferrer"><img className="filter grayscale hover:filter-none object-contain max-w-[120px] h-[auto]" src="/catalyst.png" alt="project catalyst" /></a>
            <a target="_blank" href="https://atalaprism.io/" rel="noreferrer"><img className="filter grayscale hover:filter-none object-contain max-w-[120px] h-[auto]" src="/atala.png" alt="atala prism"/></a> */}
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/amhara_auto_x2.jpg" alt="amahra bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/awash.png" alt="Awash bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/brihan.png" alt="Brihan bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/bunna.png" alt="Bunna bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/gadaabank.png" alt="Gadaa bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/coop.png" alt="Coop"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/dashen.jpeg" alt="Dashen bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/wegagen.png" alt="Wegagen bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/hibret.png" alt="Hibret bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/oromiabank.png" alt="Oromia bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/nib.jpeg" alt="Nib bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/lion.jpeg" alt="Lion bank"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/chapa.jpeg" alt="chapa"/>
             <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/safaricom.png" alt="Safaricom"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/ycomb.png" alt="Y Combinator"/>
            <img className="cursor-pointer object-contain max-w-[120px] h-[auto]" src="/mercy.png" alt="Mercy Corps"/>
            <img className="cursor-pointer object-contain max-w-[120px] max-h-[135px]" src="/v.png" alt="v"/>
            {/* <a href="/#"><img className="filter grayscale hover:filter-none object-contain max-w-[120px] h-[auto]" src="/kitvate.png" alt="kit vate"/></a> */}
            {/* <a href="https://proofspace.id"><img className="filter grayscale hover:filter-none object-contain max-w-[120px] h-[auto]" src="/proofspace.jpg" alt="proofsapce"/></a> */}
        </div>
      </Marquee> 
    </div>
  )
}

export default LogoSpin