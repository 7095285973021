import LogoSpin from "../../components/base/LogoSpin";
import FWHeroFooterSimple from "../../components/base/FWHeroFooterSimple";
import { useState } from "react";
import HowItWorks from "../../components/onboarding/HowItWorks";
import Hero from "./Hero";
import JobIndustries from "../../components/onboarding/JobIndustries";
import LoginModal from "../../components/onboarding/LoginModal";
import SignUpModal from "../../components/onboarding/SignUpModal";
import BusinessSignupModal from "../../components/onboarding/BusinessSignupModal";
import { useNavigate } from 'react-router-dom'
import ForgetPasswordModal from "../../components/onboarding/ForgetPasswordModal";

const Landing = () => {  
    const [keyword, setKeyword] = useState('')
    const [selectedEmployer, setSelectedEmployer] = useState(0)
    const [loginModal , setLoginModal] = useState(false);
    const [signUpModal , setSignUpModal] = useState(false);
    const [businessSignUpModal , setBusinessSignUpModal] = useState(false);
    const [forgetPasswordModal , setForgetPasswordModal ] = useState(false);
    const Navigate = useNavigate()

    const onSearch = (searchVal: string, employerId:number) => {
        setKeyword(searchVal)
        setSelectedEmployer(employerId)
    }

    const resetSearch = () => {
        setKeyword('')
    }
    const login = () => {
        setLoginModal(true)
        setSignUpModal(false)
        setBusinessSignUpModal(false)
      }
      const onClose = () => {
        setLoginModal(false)
        setSignUpModal(false)
        setBusinessSignUpModal(false)
        setForgetPasswordModal(false)
      }
      const onSubmit = () => {
        Navigate('/dashboard')
      }
      const onSignUp = () => {
        setSignUpModal(true)
        setLoginModal(false)
        setBusinessSignUpModal(false)
      }
      const onBusinessSignUp = () => {
        setSignUpModal(false)
        setLoginModal(false)
        setBusinessSignUpModal(true)
      }
      const OnForgetPassword = () => {
        setSignUpModal(false)
        setLoginModal(false)
        setForgetPasswordModal(true)
      }


    return (
        <div className="h-screen max-h-screen overflow-y-auto overflow-x-hidden" style={{scrollBehavior: 'smooth'}}>
            <Hero onSearch={onSearch} onSignUp={onSignUp} login={login} onBusinessSignUp={onBusinessSignUp}/>
            <JobIndustries searchValue={keyword} employerId={selectedEmployer} onResetSearch={resetSearch} onSignUp={onSignUp}/>
            <LogoSpin />
            <HowItWorks signup={onSignUp}/>
            <FWHeroFooterSimple />
            {loginModal && <LoginModal onClose={onClose} onLoginSubmit={onSubmit} onSignup={onSignUp} redirectTo={'/dashboard'} onForgetPassword={OnForgetPassword}/>}
            {signUpModal && <SignUpModal onClose={onClose} onLoginSubmit={onSubmit} onLogin={login} fromModal={false} onBusiness={onBusinessSignUp}/>}
            {businessSignUpModal && <BusinessSignupModal onClose={onClose} onLoginSubmit={onSubmit} />}
            {forgetPasswordModal && <ForgetPasswordModal onClose={onClose} onSubmit={onSubmit} onLogin={login}/>}
        </div>
    );
  };

export default Landing