import { Tabs } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'
// import EmploymentJobSeekerPage from '../employment/EmploymentJobseekerPage'
import MyJobs from './MyJobs'
// import { useAuth } from "../../context/AuthContext";


const MyJobsTabPage:React.FC<any> = () => {
    const location = useLocation();
    const showAllJobs = location.state?.showAllJobs;
    // const { currentUser} = useAuth();
    
    return(
        !showAllJobs ?
        <Tabs defaultActiveKey="1" className={'mt-2'} tabBarStyle={{marginLeft: '30px'}}>
            <Tabs.TabPane tab="My Jobs" key="1">
                <MyJobs />
            </Tabs.TabPane>
            {/* {!currentUser?.is_staff && <Tabs.TabPane tab="Employments" key="2">
                <EmploymentJobSeekerPage />
            </Tabs.TabPane>} */}
        </Tabs> : <MyJobs />
    )
}

export default MyJobsTabPage