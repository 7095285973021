import {  ShareAltOutlined ,UnorderedListOutlined, LineChartOutlined, GlobalOutlined, SwapOutlined, DeleteOutlined, ArrowLeftOutlined, ProfileOutlined, FolderAddOutlined, EnvironmentOutlined, DollarOutlined, ClockCircleOutlined, CheckCircleOutlined, BarChartOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Alert, Space } from "antd";
import { useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { applyToJob, deleteJob, editJob, getJob, withdrawApplication } from "../../services/JobService";
import { useAuth } from "../../context/AuthContext";
import { IJob } from "../../types";
import moment from "moment";
import { employmentTypeMapping, levelOfStudyMapping, trimText } from "../../Utility";
import useAlert from "../../components/toast/useAlert";
import FWBadge from "../../components/base/FWBadge";
import PageSpinner from "../../components/base/PageSpinner";
import RecruitmentSection from "./RecruitmentSection";
import FWSubHeader from "../../components/base/FWSubHeader";
import Page from "../../components/base/Page";
import { useMenu } from "../../context/MenuContext";

import CSS from 'csstype';
import LoginModal from "../../components/onboarding/LoginModal";
import SignUpModal from "../../components/onboarding/SignUpModal";
import SeeMoreJobs from "./SeeMoreJobs";
import RequirmentModal from "../../components/jobs/RequirmentModal";
import { RequirementExtraQuestions } from "../../services/UserService";
import ForgetPasswordModal from "../../components/onboarding/ForgetPasswordModal";

const JobDetail = ()=> {
    const navigate = useNavigate();
    const {isLoggedIn, currentUser} = useAuth();
    const {addAlert} = useAlert();
    
    const [requiredQuestions, setRequiredQuestions] = useState<boolean>(false);
    const [seeMoreJobsState,] = useState<boolean>(false);
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);
    const [showForgetPasswordModal, setShowForgetPasswordModal] = useState<boolean>(false);
    const [job, setJob] = useState<IJob>({} as IJob);
    const [loading, setLoading] = useState<boolean>(false);
    const [applyLoading, setApplyLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [isAccessible, setIsAccessible] = useState<boolean>(true);
    const [publishing, setPublishing] = useState<boolean>(false);
    const {jobid} = useParams();
    const {setMenuExpanded} = useMenu()

    const dividerStyle : CSS.Properties = {borderColor: '#F0F0F0', opacity: 0.5, marginLeft: '-16px', marginRight: '-16px', marginTop: '10px', marginBottom: '10px' }

    const alreadyApplied = useMemo(() => {
        return !!job.application_status
    }, [job])

    useEffect(()=> {
        if(jobid) {
            setLoading(true)
            getJob(+jobid).then(res => {
                setJob(res?.data)
            }).catch(err => {
                if(err.response.status === 403) {
                    setIsAccessible(false)
                }
                if(err.response.status === 404) {
                    navigate('/404')
                }
            })
            .finally(()=> {setLoading(false)});
        }
    }, [jobid, navigate, reload])

    const onJobApply = ()=> {
        setApplyLoading(true)
        if(!currentUser?.is_staff && job?.id) {
            applyToJob(job?.id)
            .then(res => {
                setReload(prev => !prev)
                addAlert({type:'success', title: 'Success', description: 'Applied to Job successfully', autoClose:true})
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    console.log(err.response.data) 
                    addAlert({type:'error', title: 'error', description: JSON.stringify(err.response.data)})
                }
            }).finally(()=> {setApplyLoading(false)})
        }
    }

    const onJobEdit = ()=> {
        if(job.id) {
        navigate(`/dashboard/jobs/${job?.id}/edit`)
        }
    }

    const onViewCandidates =()=> {
        if(!job.is_draft) {
            navigate(`/dashboard/selection/${job?.selection}`, {state: {job: job}})
            setMenuExpanded('compact')
        }
    }

    const onJobDelete = ()=> {
        if(!deleteIsAllowed) {
            addAlert({type: 'error', title: 'Error', description: 'Cannot delete a job is published', autoClose: true})
            return;
        }
        setLoading(true);
        if(window.confirm('Are you sure you want to delete?') && jobid) {
            deleteJob(+jobid).then(res => {
                addAlert({type: 'success', title: 'Success', description: 'Job deleted successfully', autoClose: true})
                navigate(`/dashboard`)
            }).catch(err => console.log(err))
            .finally(()=> {setLoading(false)});
        } else {
            setLoading(false);
            console.log('rejected')
        }
    }

    const onCancelApplication = ()=> {
        setLoading(true);
        if(window.confirm('Are you sure you want to cancel application?') && jobid) {
            withdrawApplication(+jobid).then(res => {
                addAlert({type: 'success', title: 'Success', description: 'Job application cancelled successfully', autoClose: true})
                navigate('/dashboard')
            }).catch(err => console.log(err))
            .finally(()=> {setLoading(false)});
        } else {
            setLoading(false);
            console.log('rejected')
        }
    }

    const Divider = () => (<hr style={dividerStyle} />)

    const jobIsExpired = useMemo(() => {
        return moment(job.deadline).add(6, 'months').isBefore(moment(), 'day')
    }, [job])

    const deleteIsAllowed = useMemo(() => {
        return job.is_draft || moment(job.deadline).add(6, 'months').isBefore(moment(), 'day')
    }, [job])

    const onPublishDraftJob = () => {
        if(job.id) {
            setPublishing(true)
            editJob({id: job.id, deadline: job.deadline, requirements: job.requirements, title: job.title, is_draft: false}).then(res => {
                addAlert({type: 'success', title: 'Success', description: 'Job published successfully', autoClose: true})
                setReload(prev => !prev)
            }).catch(err => console.log(err)).finally(()=> {setPublishing(false)})
        }
    }

    const shareLink = () => {
        navigator.clipboard.writeText(window.location.href); 
        addAlert({type: 'success', title: 'Success', description: 'Link copied to clipboard', autoClose: true})
    }

    const handleLoggedOutApplication = () => {
        setShowLoginModal(true)
    }

    const onLoginSubmit = () => {
        navigate(`/?redirectTo=/dashboard/jobs/${job.id}`)
    }

    const onCloseLoginModal = () => {
        setShowLoginModal(false)
    }

    const onCLoseSignUpModal = () =>{
        setShowSignUpModal(false)
    }

    const onForgetPasswordModal = () =>{
        setShowForgetPasswordModal(false)
    }

    const onSignUpModal = () => {
        setShowLoginModal(false)
        setShowSignUpModal(true)
    }

    const onLogin = () => {
        setShowSignUpModal(false)
        setShowLoginModal(true)
    }

    const onForgetPassword = () => {
        setShowLoginModal(false)
        setShowSignUpModal(false)
        setShowForgetPasswordModal(true)
    }

    const showRequirementModal = () => {
        onJobApply()
    }

    const onCancel = () => {
        setRequiredQuestions(false);
    }
    const onSubmit = (data:any) => {
        const go = (data:any) => {
            RequirementExtraQuestions(data).then((response) => {
                if(response?.status === 201) {
                    addAlert({type: 'success', title: 'success', description: 'Successfully sent Requirement Application!', autoClose:true})
                    setRequiredQuestions(false);
                    onJobApply()
                }
            }, (error) => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(error?.response?.data), autoClose:false})
            }).catch(err => console.log(err))
        }
        if(data?.user){
            go(data) 
        }
        else {
            const data = {
                "user":currentUser?.id,
                "job": job?.id,
                "has_relative": false,
                "relatives": []
            };
            go(data) 
        }
    }
    return(
        <>
        {requiredQuestions && <RequirmentModal onCancel={onCancel} onSubmit={onSubmit} jobId={job?.id} userId={currentUser?.id} />}
        {seeMoreJobsState ? 
            <div className="p-10">
                <h1 className="ml-2 text-2xl">Available Jobs</h1>
                <SeeMoreJobs />
            </div>  
        :<Page className={!isLoggedIn() ? 'flex justify-center' : ''}>
            {isLoggedIn() && <FWSubHeader title={`Job: ${job?.title ?? ''}`}>
                <span onClick={() => currentUser?.is_staff ? navigate('/dashboard') : navigate(-1)} className={'flex ml-4 items-center cursor-pointer'}>
                    <ArrowLeftOutlined className={'mr-2 text-fw-orange font-medium scale-150'} />
                    <p className={'text-xs text-gray-600'}>Back to Jobs</p>
                </span>
            </FWSubHeader>}
            {!loading ?
            <div className={'bg-white shadow rounded-xl p-4 md:w-auto xl:max-w-6xl'}>
                {!isAccessible ?
                <div className={'p-6 grid place-items-center'}>
                    <p className={'text-gray-600 font-bold text-lg mb-2'}>OOPS...You have no access to view this job</p>
                    <img alt="not_accessible" src={'/img/access_denied.svg'} className={'w-48 h-auto mx-auto'} />
                </div> :
                
                <>
                <div className={'flex flex-col md:flex-row md:justify-between w-full md:flex-wrap'}>
                    <div className={'flex flex-row flex-wrap mb-2 items-center'}>
                        <p className={'text-xl text-gray-700 pr-3 font-semibold capitalize'}>{job.title}</p>
                        <p className={'text-sm text-gray-400 pr-3 font-light'}>{job.is_draft ? 'Draft created' : 'Posted'}: {job?.date_posted ? moment(job?.date_posted).add(6, 'months').fromNow(): ''}</p>
                        <p className={'text-sm text-gray-400 pr-3 font-light'}>Updated: {job?.date_updated ? moment(job?.date_updated).add(6, 'months').fromNow(): ''}</p>
                    </div>

                    <div className={'flex items-center flex-wrap gap-2'}>
                        <div className={`flex items-center rounded-md px-2 py-1 bg-yellow-100 text-fw-orange font-semibold ${isLoggedIn() ? '' : 'blur-[2px]'}`}>
                            <span className={'text-gray-400 font-normal pr-2 text-xs'}>Deadline:</span>
                            <ClockCircleOutlined className={'mr-2'} />
                             {moment(job?.deadline).add(6, 'months').format('LL')}
                        </div>

                        {isLoggedIn() && <Button onClick={shareLink} type={'default'} className={'flex items-center'} ><ShareAltOutlined />Share</Button>}

                        {currentUser?.is_staff ? <>
                            <Button onClick={onViewCandidates} type={job.is_draft ? 'ghost' : 'primary'} className={'flex items-center'} ><CheckCircleOutlined />{job.is_draft ? 'Draft' : 'View Selection'}</Button>
                            <Button onClick={onJobEdit} className={'flex items-center bg-fw-green hover:bg-fw-green focus:bg-fw-green text-white focus:text-white'} ><CheckCircleOutlined />Edit</Button>
                            {job.is_draft && <Button onClick={onPublishDraftJob} disabled={publishing} loading={publishing} type={'primary'} ><CheckCircleOutlined />Publish</Button>}
                            <Button disabled={!deleteIsAllowed} onClick={onJobDelete} className={`flex items-center ${!deleteIsAllowed ? 'bg-gray-100 text-gray-400 opacity-25' : 'bg-red-700 hover:bg-red-700 focus:bg-red-700 text-white focus:text-white'} `} ><DeleteOutlined /></Button>
                        </> :
                        <>
                        {alreadyApplied && <Button onClick={onCancelApplication} className={'flex items-center bg-red-700 hover:bg-red-700 focus:bg-red-700 text-white focus:text-white mr-4'}>Withdraw Application</Button>}
                        {isLoggedIn() && <Button loading={applyLoading} disabled={applyLoading || alreadyApplied || jobIsExpired || !isLoggedIn()} onClick={showRequirementModal} type={'primary'} className={'flex items-center'} ><CheckCircleOutlined />{jobIsExpired ? 'Expired' : alreadyApplied? 'Applied' : 'Apply'}</Button>}
                        {/* {!isLoggedIn() && <Button onClick={() => navigate(`/?redirectTo=/dashboard/jobs/${job.id}`)} type={'default'} ><UserOutlined className={'mr-2'} />Sign in / Register to apply</Button>} */}
                        {!isLoggedIn() && <div className={'flex flex-row-reverse items-center gap-2'}><Button size={"middle"} onClick={handleLoggedOutApplication} type={'primary'} ><UserOutlined className={'mr-2'} />Apply Now!</Button>
                        <Button onClick={() => navigate('/')} type={'default'} ><UnorderedListOutlined className={'mr-2'} />See More Jobs</Button></div>  
                        }
                        </>}
                    </div>
                </div>
                
                {currentUser && alreadyApplied && !currentUser?.is_staff && <RecruitmentSection selectionId={job.selection ?? 0} yourStage={job?.application_status?.status ?? []} jobStage={job?.stage ?? []} />}
                <div className={'text-gray-400 font-light text-sm leading-5 mt-4 mb-5'}>
                    {job?.description}
                </div>

                <div className={'flex mb-4 flex-wrap'}>
                    <FWBadge text={job?.location} icon={<EnvironmentOutlined className={'mr-2 text-gray-600'} />} className={'bg-gray-200 mr-6 mb-2 whitespace-nowrap'} />
                    <FWBadge text={employmentTypeMapping.find(item => item.id === job?.employment_type)?.value ?? 'N/A'} icon={<ProfileOutlined className={'mr-2 text-yellow-600'} />} className={'bg-yellow-100 mr-6 mb-2 whitespace-nowrap'} />
                    <FWBadge text={Number(job.salary) !== 0 && job.salary ? `ETB ${job?.salary}/mo` : 'As per Company Scale'} icon={<DollarOutlined className={'mr-2 text-green-500'} />} className={'bg-green-50 mr-6 mb-2 whitespace-nowrap'} />
                    <FWBadge text={'No. of Positions'} icon={<div className={'px-2 py-1/2 bg-gray-700 text-white rounded-xl w-auto h-full mr-2'} >{job?.number_of_positions}</div>} className={'bg-gray-100 mr-6 mb-2 whitespace-nowrap'} />
                    <FWBadge text={`Stage: ${job.is_draft ? 'Draft (Not Published)': job?.stage ? [...job?.stage]?.slice(-1) : ''}`} icon={<BarChartOutlined className={'mr-2 text-green-500'} />} className={'bg-green-50 mb-2 whitespace-nowrap mr-4'} />
                    {job.gender && <FWBadge text={`Gender: ${job?.gender}`} icon={<SwapOutlined className={'mr-2 text-green-500'} />} className={'bg-green-50 mb-2 whitespace-nowrap'} />}
                </div>
                {!isLoggedIn() && <Space direction="vertical" style={{ width: '100%' }}><Alert
                    message="Benefits of Signing up"
                    description={<ul>
                        <li>Signup now and get free consultation for your application process via email</li>
                        <li>Access our community and expert advisors</li>
                        <li>Your Digital CV will be reusable for every job!</li>
                        </ul>}
                    type="info"
                    showIcon
                /></Space>}

                <Divider />
                <h1 className={'mt-2 mb-4 text-md text-gray-800 font-bold'}>Requirements</h1>

                <div className={'flex flex-row mb-2 items-center'}>
                    <span className={'flex flex-row text-gray-500 font-medium min-w-[120px] items-center'}>
                        <img alt="icon" className={'pr-3'} src={'/carbon_education.svg'} width="30px" height="30px"/>
                        Education
                    </span>
                    
                    <div className={'text-sm text-gray-600'}>
                        {job?.level_of_study ? `${levelOfStudyMapping.find(item => item.id === job?.level_of_study)?.value} in` : ''} {job?.field_of_study ? Array.isArray(job?.field_of_study) ? job.field_of_study.join(',') : job.field_of_study : 'Any Field'}
                    </div>
                </div>

                <div className={'flex flex-col mb-2 md:flex-row md:items-center'}>
                    <span className={'flex flex-row text-gray-500 font-medium min-w-[120px] items-center'}>
                        <img alt="icon" className={'pr-3'} src={'/carbon_certificate.svg'} width="30px" height="30px"/>
                        Skills
                    </span>
                    
                    <div className={'flex flex-wrap'}>
                        {job?.skills?.split(',')?.map((skill,i) =>
                            <FWBadge key={i} text={skill} className={'bg-blue-100 text-blue-500 border-blue-500 text-xs mr-4 mb-2 whitespace-nowrap'} />
                        )}
                    </div>
                </div>

                <div className={'flex flex-col mb-2 md:flex-row md:items-center'}>
                    <span className={'flex flex-row text-gray-500 font-medium min-w-[120px] items-center'}>
                        <LineChartOutlined className={'pr-3 text-fw-orange w-[30px]'} />
                        Experience
                    </span>
                    
                    <div className={'text-sm text-gray-600'}>
                        {job.years_of_experience ? `${job.years_of_experience} Years` : 'Not Applicable'}
                    </div>
                </div>


                {job?.languages && job?.languages?.length > 0 && <div className={'flex flex-row mb-2  '}>
                    <span className={'flex flex-row text-gray-500 font-medium w-40'}>
                        <GlobalOutlined className={'text-fw-orange text-base mr-4'} />
                        Languages
                    </span>
                    
                    <div className={'text-sm text-gray-600 capitalize'}>
                        {(job?.languages && job?.languages?.length > 0) ? job.languages.join(', ') : 'N/A'}
                    </div>
                </div>}

                {job.max_age && <div className={'flex flex-row mb-2'}>
                    <span className={'flex flex-row text-gray-500 font-medium w-40'}>
                        <BarChartOutlined className={'text-fw-orange text-base mr-4'} />
                        Age
                    </span>
                    
                    <div className={'text-sm text-gray-600 capitalize'}>
                        {job?.max_age ? `Not more than ${job.max_age}` : 'Not set'}
                    </div>
                </div>}

                <div className={'text-gray-400 font-light text-sm leading-5 mt-4 mb-5'}>
                    {job?.requirements}
                </div>
                {job?.responsibilities && job?.responsibilities?.length > 0 &&
                <>
                <Divider />
                <h1 className={'mt-2 mb-4 text-md text-gray-800 font-bold'}>Duties and Responsibilities</h1>
                {job?.responsibilities?.map((duty,j) => {
                    return (<div key={j} className={'flex items-center mb-2'}>
                            <img alt="icon" className={'pr-2'} src={'/clarity_dot-circle-line.svg'} width="25px" height="25px"/>
                            <p className={'font-thin text-sm ml-2 text-gray-400'}>{duty}</p>
                    </div>)})}
                </>}
                <Divider />
                
                <div className={'mb-4'}>
                    <p className={'pt-2 pb-3 text-lg text-gray-800 font-bold inline-flex'}>{job?.business?.name}
                        <img alt="icon" className={'pl-1 ml-1'} src={'/ic_baseline-verified.svg'} width="25px" height="25px"/>
                    </p>

                    <div className={'flex md:items-center justify-between flex-col gap-4 md:flex-row'}>
                        <div className={'flex items-center'}>
                            <img alt="icon" src={job?.business?.logo ?? '/company-s.png'} className={'rounded'} width="60px" height="60px"/>
                            <div className={'ml-4 text-gray-400 leading-4 md:w-60 text-sm'}>
                                {trimText(job?.business?.description ?? '', 170)}
                            </div>
                        </div>
                        
                        <div style={{height: 52}} className={'flex justify-self-stretch flex-col justify-between'}>
                            <div className={'flex items-center'}>
                                <FolderAddOutlined className={'font-semibold transform scale-125 text-fw-orange'} />
                                <p className={'font-thin text-sm ml-2 text-gray-400'}>{`Established: ${moment(job?.business?.year_established).format('YYYY')}`}</p>
                            </div>
                            <div className={'flex items-center'}>
                                <EnvironmentOutlined className={'font-semibold transform scale-125  text-gray-700'} />
                                <p className={'font-thin text-sm ml-2 text-gray-400 capitalize'}>{`${job?.location ?? ''}, Ethiopia`}</p>
                            </div>
                        </div>

                        <div style={{height: 52}} className={'flex justify-self-stretch flex-col justify-between'}>
                            <div className={'flex items-center'}>
                                <FolderAddOutlined className={'font-semibold transform scale-125 text-fw-orange'} />
                                <p className={'font-thin text-sm ml-2 text-gray-400'}>{`Sector: ${job?.business?.sector ?? ''}`}</p>
                            </div>
                            <div className={'flex items-center'}>
                                <img alt="icon" src={'/clarity_employee-group-solid.svg'} width="25px" height="25px"/>
                                <p className={'font-thin text-sm ml-2 text-gray-400'}>{`${job?.business?.number_of_employees ?? ''}+ employees`}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </>}
            </div> : <PageSpinner />}
        </Page>}
            {showLoginModal && !showSignUpModal && !isLoggedIn() &&
                <LoginModal
                    onForgetPassword={onForgetPassword}
                    onSignup={onSignUpModal}
                    onLoginSubmit={onLoginSubmit}
                    onClose={onCloseLoginModal}
                    redirectTo={`/dashboard/jobs/${job.id}`}
                />
            }
            {showSignUpModal && !showLoginModal && !isLoggedIn() &&
                <SignUpModal
                    fromModal={true}
                    onLogin={onLogin}
                    onLoginSubmit={onLoginSubmit}
                    onClose={onCLoseSignUpModal}
            />}
            {!showLoginModal && !showSignUpModal && showForgetPasswordModal && !isLoggedIn() &&
                <ForgetPasswordModal
                    onSubmit={onForgetPassword}
                    onLogin={onLogin}
                    onClose={onForgetPasswordModal}
                />
            }
        </>
    )
}

export default JobDetail;