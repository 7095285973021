import { Alert, Select } from "antd"
import Modal from "../../components/base/Modal"
import { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { getCVEducation } from "../../services/CVService"
import useAlert from '../../components/toast/useAlert';
import { ICVEducation } from "../../types"
import { createCredentials } from "../../services/UserService"

interface ICredentialRequestModal {
    onClose: () => void
    onSubmit: () => void
}

const CredentialRequestModal = ({onClose, onSubmit}:ICredentialRequestModal) => {
    const [education, setEducation] = useState<ICVEducation[]>([])
    const [selected, setSelected] = useState<Number>(0)
    const [count, setCount] = useState<Number>(0)
    const [options, setOptions] = useState<any[]>([])
    const {currentUser} = useAuth();
    const {addAlert} = useAlert();

    useEffect(() => {
        if(localStorage.getItem('fw-cvId')){
            getCVEducation(Number(localStorage.getItem('fw-cvId'))).then(res=>{
            // addAlert({type: 'success', title: 'success', description: 'Program Updated Successully!', autoClose: true})
            sortEducation(res?.data)
            }).catch(err => {
                console.log(err)
                // addAlert({type: 'error', title: 'error', description: 'Failed To Get Dat!', autoClose: true})
            })
        }
    }, [currentUser])

    const onFinish = () => {
        if(selected !== 0){
            education.forEach((edu:ICVEducation) => {
                if(edu.id === selected){
                    const enrollmentYear = new Date(String(edu.start_date)).getFullYear();
                    const graduationYear = new Date(String(edu.end_date)).getFullYear();
                    createCredentials({
                        "year_of_enrollment": enrollmentYear, 
                        "year_of_graduation": graduationYear , 
                        "student_id": currentUser?.id,
                        "university": edu.university,
                        "program": edu.program}).then(res => {
                            addAlert({type: 'success', title: 'success', description: 'Credential Created Successully!', autoClose: true})
                            onSubmit()
                        }).catch(err=> {
                            console.error(err)
                            addAlert({type: 'error', title: 'error', description: 'Failed To Create Credential!', autoClose: true})
                        })
        }})
        }
       onClose()
    }

    const sortEducation = (data:any) => {
        
        data.forEach((edu:ICVEducation) => {
            if(edu.university && edu.program){
                setCount(prev => Number(prev)+1)
                setEducation(prev => [...prev, edu])
                setOptions(prev => [...prev, {
                    value: edu.id,
                    label: edu.field_of_study,
                  }])
            }
            else
                setOptions(prev => [...prev, {
                    value: edu.id,
                    label: edu.field_of_study,
                    disabled: true,
                }])
            })
    }

    const handleSelect = (value: string) => {
        setSelected(Number(value))
      };

  return (
    <Modal className="md:w-[600px]" title='Request Credential' subTitle="Select Education to request a credential" onCancel={onClose} onSubmit={onFinish}>
        {count === 0 && <Alert
      message="Warning"
      description="Currently the Education you have registered are from Institues that are not onboarded on our site!"
      type="error"
      showIcon
    />}
        <Select style={{ width: 500 }} className="mt-6" placeholder="Select Education"
        options={options} onChange={handleSelect}></Select>
    </Modal>
  )
}

export default CredentialRequestModal