import { Avatar, Button } from "antd";
import { FC, useState } from "react";
import { ISelection } from "../../types";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import PhoneOutlined from "@ant-design/icons/lib/icons/PhoneOutlined";
import { useNavigate } from "react-router-dom";
import { getProfileImgUrlFromItem } from "../../Utility";
import { exportFinishedCandidates } from "../../services/SelectionService";
import { exportCVtoPDF } from "../../services/CVService";
import fileDownload from "js-file-download";
import useAlert from "../../components/toast/useAlert";
import jszip from 'jszip';
import EmploymentInvitationModal from "../employment/EmploymentInvitationModal";
interface IFinishedWagonDetail {
    selection: ISelection
}

const FinishedWagonDetail:FC<IFinishedWagonDetail> = ({ selection }) => {
    const navigate = useNavigate()
    const {addAlert} = useAlert()
    const [downloading, setDownloading] = useState(false)
    const [cvDownloading, setCvDownloading] = useState(false)
    const [showInvitationModal, setShowInvitationModal] = useState(false)
    const [inivitationSent, setInvitationSent] = useState(false)
    const [name, setName] = useState('')

    const onExportCV = () => {
        setDownloading(true)
        exportFinishedCandidates(selection.id).then((res) => {
            return {data : res?.data, name: res?.headers['filename'] ?? `Selection-Details.xlsx`}
            }).then(({data, name}) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(data);
                link.download = name;
                link.click();
        }).finally(() => setDownloading(false))
    }

    const onDownloadCVs = () => {
       setCvDownloading(true)
       const data = selection?.selected_cvs ?? []
       if(data && data.length > 0) {
            const zip = new jszip()
            data.forEach((item, index) => {
                exportCVtoPDF(item.cv).then((file) => {
                    const {first_name, last_name} = item.cv?.user?.profile
                    const fileName = `${first_name}_${last_name}_CV.pdf`
                    zip.file(fileName, file?.data)
                    if(index === data.length - 1){
                        zip.generateAsync({type:"blob"}).then((content) => {
                            fileDownload(content, "CVs.zip")
                        })
                    }
                }).catch((ex) => {
                    console.log(ex)    
                    addAlert({type: 'error', title: 'Error', description: 'CV Export failed. Please try again later!', autoClose: true})
                }).finally(() => {
                    setCvDownloading(false)
                })
            })
       }
    }

    const handleHire = (e:any, name: string) => {
        e.preventDefault()
        e.stopPropagation()
        setName(name)
        setShowInvitationModal(true)
    }

    return(
        <div className={'flex flex-col gap-1 text-gray-500 mt-4 ml-2'}>
            <p className={'text-lg font-bold'}>Selection Closed: <span className={'text-fw-orange'}>Interview for Final Hire</span>
                <Button onClick={onExportCV} disabled={downloading} loading={downloading} type={'primary'} className={'w-max ml-4'} size={'small'}>Download Selection Excel</Button>
                <Button onClick={onDownloadCVs} disabled={cvDownloading || selection?.selected_cvs?.length === 0} loading={downloading} type={'primary'} className={'w-max ml-4'} size={'small'}>Download CVs</Button>
            </p>
            <div className={'flex flex-wrap'}>
                {selection.selected_cvs?.map((item) => (
                    <div onClick={() => navigate(`/dashboard/cv/${item.cv.cv}`)} className={'hover:bg-gray-50 cursor-pointer mt-4 flex flex-col w-80 min-h-[10.5rem] rounded-lg shadow-lg pt-4 mr-4 mb-4 overflow-hidden'}>
                        <div className={'flex gap-4 px-4'}>
                            <Avatar src={getProfileImgUrlFromItem(item)} className={'rounded-md'} shape={'square'} size={120} style={{minWidth:120}}/>  
                            <div className={'flex flex-col'}>
                                <p className={'text-fw-orange text-base font-semibold'}>{item?.cv?.profile[0].first_name} {item?.cv?.profile[0].last_name}</p>
                                <p className={'text-gray-700 font-medium text-xs'}>{item?.cv?.profile[0].title.slice(0,50)}</p>
                                <p className={'mt-1 text-gray-500 text-xs font-thin'}>
                                    {item?.cv?.profile[0].bio.slice(0, 70)}...
                                </p>
                            </div>    
                        </div>
                        <div className={'mt-1 flex justify-between px-4 text-gray-500 font-medium text-sm py-1'}>
                           <Button disabled={inivitationSent} onClick={(e:any) => handleHire(e, item.cv.user.profile.first_name)} type={'primary'} size={'small'} className={'text-sm'}>{inivitationSent ? 'Offer Sent' : 'Hire'}</Button>
                        </div>
                        <div className={'bg-gray-200 mt-1 flex justify-between px-4 text-gray-500 font-medium text-sm py-1'}>
                            <p className={''}>
                                <PhoneOutlined className={'mr-1'}/>
                                {item?.cv?.profile[0].phone_number}
                            </p>
                            <p>
                                <MailOutlined className={'mr-1'}/>
                                {item?.cv?.profile[0].email}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            {showInvitationModal && 
                <EmploymentInvitationModal
                    name={name}
                    onClose={() => setShowInvitationModal(false)}
                    onSubmit={() => {
                        setShowInvitationModal(false)
                        setInvitationSent(true)
                        addAlert({type: 'success', title: 'success', description: 'Employment Letter Sent Successfully!', autoClose: true})
                    }}
                />}
        </div>
    )
}

export default FinishedWagonDetail

