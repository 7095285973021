import { IBusiness, INotification, IProgram, IUniversity, IUser } from "../types";
import Request from "./Request"

export const getUser = (id:number) => {
  return Request('GET', `/users/${id}/`);
} 

export const updateUser = (body:IUser) => {
  return Request('PUT', `/users/${body.id}/`, body);
} 

export const updateUserPhoto = (id:number, body:FormData) => {
  return Request('PATCH', `/users/${id}/`, body);
} 

export const getOrganizationProfile = (id:number) => {
  return Request('GET', `/business/${id}/`);
} 

export const getUniversities = () => {
  return Request('GET', `/universities/`);
} 

export const getUniversityProfile = (id:number) => {
  return Request('GET', `/universities/${id}/`);
} 

export const updateOrganizationProfile = (body:IBusiness) => {
  return Request('PUT', `/business/${body?.id}/`, body);
} 

export const updateUniversityProfile = (body:IUniversity) => {
  return Request('PUT', `/universities/${body?.id}/`, body);
} 

export const updateOrganizationLogo = (id:number, body:FormData) => {
  return Request('PATCH', `/business/${id}/`, body);
} 

export const updateUniversityLogo = (id:number, body:FormData) => {
  return Request('PATCH', `/universities/${id}/`, body);
} 

export const getOrganizationStaff = () => {
  return Request('GET', `/business/`);
} 

export const acceptBusinessInvitation = (body: {"password": string,"invitation_code": string}) => {
  return Request('POST', `/invitation/accept_invite/`, body);
}

export const getBusinessInvitation = (token: string) => {
  return Request('GET', `/invitation/${token}/`);
}

export const getNotifications = () => {
  return Request('GET', `/notifications/`);
}

export const markNotificationRead = (id: number, body: INotification) => {
  return Request('PUT', `/notifications/${id}/`, body);
}

export const RequirementExtraQuestions = (body: {"data": JSON}) => {
  return Request('POST', `/dashenextraquestion/`, body);
}

export const getCredentials = () => {
  return Request('GET', `/credential_request/`);
} 

export const createCredentials = (body: any) => {
  return Request('POST', `/credential_request/`, body);
} 

export const IssueCredential = (body: any) => {
  return Request('POST', `/issue_credential/`, body);
} 

export const RejectCredential = (body: any) => {
  return Request('POST', `/reject_credential/`, body);
}

export const getPrograms = () => {
  return Request('GET', `/programs/`);
} 

export const getProgram = (id:number) => {
  return Request('GET', `/programs/${id}`);
} 

export const createProgram = (body:IProgram | FormData) => {
  return Request('POST', `/programs/`, body);
} 

export const updateProgram = (id: Number, body:Partial<IProgram> | FormData) => {
  return Request('PATCH', `/programs/${id}/`, body);
} 