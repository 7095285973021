import axios, { AxiosRequestHeaders } from "axios";
import { storeToken } from "../context/AuthContext";
import createAuthRefreshInterceptor from 'axios-auth-refresh';

type HttpVerb = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
export const baseAddress = process.env.REACT_APP_API
export const exportAddress = process.env.REACT_APP_EXPORT_API

const Request = async (verb: HttpVerb, url: string, body: any = {}, isExport?: boolean, resType?: string) => {
    const apiUrl = !isExport ? baseAddress : exportAddress
    // Obtain the fresh token each time the function is called
    function getAccessToken(){
      return localStorage.getItem('fw-token');
    }

    function getRefreshToken(){
      return localStorage.getItem('fw-refreshToken');
    }

    // Use interceptor to inject the token to requests
    axios.interceptors.request.use(request => {
      if(request.headers && getAccessToken()) {
        request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
      }
      return request;
    });

    // Function that will be called to refresh authorization
    const refreshAuthLogic = (failedRequest:any) => axios.post(baseAddress + '/session/refresh/', {refresh: getRefreshToken()}).then(tokenRefreshResponse => {
        storeToken(tokenRefreshResponse.data.access);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access;
        return Promise.resolve();
    }).catch((ex) => {
      console.log('Session Expired', ex)
      window.localStorage.clear()
      window.location.reload()
    })

    // Instantiate the interceptor
    createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes:[401]});

    // Make a call. If it returns a 401 error, the refreshAuthLogic will be run, 
    // and the request retried with the new token
    const config: AxiosRequestHeaders = {
      responseType: isExport ? 'blob' : resType ? resType : 'json',
    } 

    if(verb === 'POST') {
      return axios.post(apiUrl + url, body, config)
    }
    if(verb === 'GET') {
      return axios.get(apiUrl + url, config)  
    }
    if(verb === 'PUT') {
      return axios.put(apiUrl + url, body, config)
    }
    if(verb === 'PATCH') {
      return axios.patch(apiUrl + url, body, config)
    }
    if(verb === 'DELETE') {
      return axios.delete(apiUrl + url, config)  
    }
}

export default Request;
