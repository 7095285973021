import EnvironmentOutlined from "@ant-design/icons/lib/icons/EnvironmentOutlined";
import DollarCircleOutlined from "@ant-design/icons/lib/icons/DollarCircleOutlined";
import { Button} from "antd";
import moment from "moment";
import { useNavigate } from "react-router";
import { IJob } from "../../types";
import BarChartOutlined from "@ant-design/icons/lib/icons/BarChartOutlined";
import Avatar from "antd/lib/avatar/avatar";
import { useMemo, useState } from "react";
import { trimText } from "../../Utility";
import useDevice from "../../hooks/useDevice";
import { convertNumberToK } from "../../Utils";
import { useMenu } from "../../context/MenuContext";
import useAlert from "../../components/toast/useAlert";
import { useAuth } from '../../context/AuthContext';
import RequirmentModal from "../../components/jobs/RequirmentModal";
import { RequirementExtraQuestions } from "../../services/UserService";

interface IJobListItem {
    job: IJob;
    isStaff: boolean;
    userMyJobs: boolean;
    onApply?: (jobId:number, onSuccess:()=>void)=> void;
}


const JobListItem = ({job, isStaff, userMyJobs, onApply}:IJobListItem)=> {

    const { isMobile } = useDevice();
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();
    const {setMenuExpanded} = useMenu()
    const {addAlert} = useAlert();
    const [applyLoading, setApplyLoading]= useState<boolean>(false)
    const [requiredQuestions, setRequiredQuestions] = useState<boolean>(false);

    const onViewDetail = (e:any) => {
        if(userMyJobs) {
            navigate(`/dashboard/jobs/${job?.id}`, {state: {disableApplication: true}})
            return;
        }
        navigate(`/dashboard/jobs/${job?.id}`, {state: {disableApplication: true}})
    }

    const onSelectCandidate = (e:any)=> {
        e.preventDefault();
        e.stopPropagation();
        if(!job.is_draft) {
            navigate(`/dashboard/selection/${job?.selection}`, {state: {job}})
        }
        setMenuExpanded('compact')
    }

    const handleApply = (e:any)=> {
        e.preventDefault();
        e.stopPropagation();
        setApplyLoading(true);
        if (job?.id && onApply) {
            onApply(job.id, ()=> {
            setApplyLoading(false)
            });
        }
    }

    const jobIsExpired = useMemo(() => {
        return moment(job.deadline).add(6, 'months').isBefore(moment(), 'day')
    }, [job])

    const onCancel = () => {
        setRequiredQuestions(false);
        setApplyLoading(false);
    }
    const onSubmit = (data:any) => {
        const go = (data:any) => {
            RequirementExtraQuestions(data).then((response) => {
                if(response?.status === 201) {
                    setRequiredQuestions(false);
                    setApplyLoading(true);
                    if(job?.id && onApply) {
                        onApply(job.id, ()=> {
                            setApplyLoading(false)
                        })
                    }
                }
            }, (error) => {
                addAlert({type: 'error', title: 'error', description: JSON.stringify(error?.response?.data), autoClose:false})
            }).catch(err => console.log(err))
        }
        if(data?.user){
            go(data) 
        }
        else {
            const data = {
                "user":currentUser?.id,
                "job": job?.id,
                "has_relative": false,
                "relatives": []
            };
            go(data) 
        }
    }

    return(
        <>
        <div onClick={onViewDetail} className={`${job.is_draft ? 'bg-gray-200 opacity-90' : 'bg-white'} cursor-pointer rounded-xl md:rounded-3xl shadow-md mb-5 w-auto xl:max-w-[85rem] px-6 py-4`}>
            <div className={'w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-rows-1 md:grid-rows-2 lg:grid-rows-1 gap-3 md:gap-4'}>
                    <div className={'col-span-1 md:col-span-1 flex items-center overflow-hidden'}>
                        <Avatar
                            size={isMobile ? 60 : 80}
                            className={'flex shrink-0'}
                            src={job?.business?.logo ?? '/company-s.png'}
                            shape={'circle'}
                        />
                        <div className={'flex flex-col ml-4 xl:w-full'}>
                            <p className={'font-semibold text-lg text-gray-900 pb-1 capitalize w-full flex-wrap'}>{job?.title}</p>
                            <p className={'font-light text-md text-gray-700 pb-1 capitalize'}>{job?.location}</p>
                            <p className={'font-thin text-sm text-gray-500 pb-1'}>Posted:{job?.date_posted ? moment(job?.date_posted).add(6, 'months').fromNow(): ''}</p>
                        </div>

                    </div>

                    <div className={'col-span-1'}>
                        <div className={'flex flex-row items-start w-full'}>
                            <div className={'flex flex-col mx-2 h-auto md:max-h-26 pt-2 overflow-hidden'}>
                                <p className={'font-light text-sm text-gray-400'}>{job?.description ? trimText(job?.description, isMobile ? 150 : 250) : ''}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className={'col-span-1 md:col-span-2'}>
                        <div className={'w-full h-full flex flex-row items-center flex-wrap md:flex-nowrap'}>
                            <div className={'flex flex-row gap-3 items-start w-full xl:w-max mb-4 flex-wrap md:flex-nowrap md:gap-x-2 justify-start md:justify-between xl:justify-around md:mb-0'}>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_gray rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <EnvironmentOutlined className={'font-semibold text-gray-600 transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-xs inline-block truncate'}>{`${job?.location[0]}`}
                                            <sup>{`${job.location.length> 1 ? `+${job.location.length-1}` : ''}`}</sup>
                                        </p>
                                    </div>
                                </div>
                                <div className={'flex flex-row mb-3 md:mb-0'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_yellow rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <BarChartOutlined className={'font-semibold text-fw_job_shiro transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-xs inline-block  whitespace-pre-wrap w-full text-center'}>{`${job?.stage ? [...job.stage].pop() : ''}`}</p>
                                    </div>
                                </div>

                                <div className={'flex flex-row'}>
                                    <div className={'w-20 md:w-22 h-16 md:h-20 bg-fw_job_green rounded-xl flex flex-col justify-between items-center p-2 pt-4 pb-3'}>
                                        <DollarCircleOutlined className={'font-semibold text-fw-green transform scale-125 md:scale-175'} />
                                        <p className={'text-gray-600 text-[10px] inline-block truncate pt-1'}>{job?.salary ? convertNumberToK(job?.salary) : 'Company Scale'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-row items-start w-full xl:w-full md:ml-4'}>
                                <div className={'flex flex-row w-full justify-start lg:justify-between flex-wrap gap-2 lg:flex-col lg:h-20'}>
                                    {isStaff ?
                                    <div onClick={onSelectCandidate} className={`${job.is_draft ? 'bg-red-300' : 'bg-fw-green'} text-white text-xs rounded-2xl w-max lg:w-full px-4 py-2 cursor-pointer whitespace-nowrap text-center`}>
                                        {job.is_draft ? 'Draft' : 'View Selection'}
                                    </div> : 
                                    userMyJobs ?
                                    <div onClick={onSelectCandidate} className={`${job?.has_hired ? 'bg-fw-green' : 'bg-yellow-100'} text-gray-400 text-xs rounded-2xl w-max lg:w-full px-4 py-2 cursor-pointer whitespace-nowrap text-center`}>
                                        {job?.has_hired ? 'Hiring Completed' : <span><span className={'ml-2 rounded-lg px-2 bg-yellow-500 text-white'}>Stage:&nbsp;{job?.application_status ? [...job?.application_status?.status].slice(-1) : ''}</span></span> }
                                    </div> :
                                     <Button disabled={applyLoading || jobIsExpired} onClick={handleApply} loading={applyLoading} className={'rounded-full w-max lg:w-full'} type={'primary'}>{jobIsExpired ? 'Expired' : 'Apply'}</Button>
                                    }
                                    {isLoggedIn() ? <Button onClick={onViewDetail} className={'rounded-full w-max lg:w-full'} type={'dashed'}>View Detail</Button> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        {requiredQuestions && <RequirmentModal onCancel={onCancel} onSubmit={onSubmit} jobId={job?.id} userId={currentUser?.id} />}
        </>
    )
}

export default JobListItem;
